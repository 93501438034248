import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store"
import ClientAPI from "./ClientAPI";

export default class ScanService {
  private readonly client: AxiosInstance;

  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async postPickShipment(payload: any) {
    return ClientAPI.Post(`scan/pickShipment`, payload)
  }

  async postShipment(payload: any) {
    return ClientAPI.Post(`scan/shipment`, payload)
  }

  async postPackShipment(payload: any) {
    return ClientAPI.Post(`scan/packShipment`, payload)
  }

  async postStartJob(payload: any) {
    return ClientAPI.Post(`scan/startJob`, payload)
  }


  async postEndJob(payload: any) {
    return ClientAPI.Post(`scan/endJob`, payload);
  }

  async postShopTransaction(payload: any) {
    return ClientAPI.Post(`scan/shopTransaction`, payload);
  }

  async postWorkOrderCompletion(payload: any) {
    return ClientAPI.Post(`scan/workOrderCompletion`, payload);
  }
}
